.icon-block{
    padding-left: 60px;
    padding-right: 20px;

    h5{
        position: relative;
        font-size: 16px;
        padding-top: 5px;

        .icon{
            position: absolute;
            top: 0px;
            right: 100%;
            margin-right: 10px;
            color: #fff;
            opacity: 0.7;
            font-size: 26px;
        }
    }

    @include media-breakpoint-up(lg){
        p{
            font-size: 15px;
        }
    }

    @include media-breakpoint-down(md){
        p{
            font-size: 14px;
        }
    }
}