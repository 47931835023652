ul{
    li{
        font-family: $body-font;
    }

    &.inline-list{
        width: 100%;
        display: inline-block;
        padding: 0px;

        li{
            display: inline-block;
            list-style-type: none;
            padding: 0px;
            margin: 0px;
        }
    }

    &.plain-list{
        width: 100%;
        display: block;
        padding: 0px;

        li{
            display: block;
            list-style-type: none;
            padding: 0px;
            margin: 0px;
        }
    }

    &.bullet-list{
        width: 100%;
        display: block;
        padding: 0px;
        list-style-type: disc;
        list-style-position: inside;

        li{
            display: block;
            list-style-type: disc;
            list-style-position: inside;
            padding: 0px;
            margin: 0px;
        }
    }
}