// Fonts
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Jquery UI
@import '~jquery-ui/themes/base/all.css';

// Font Awesome
@import '~@fortawesome/fontawesome-free/css/all';

// Slick Carousel
@import '~slick-carousel/slick/slick';

// Utilities
@import 'utils/mixins';
@import 'utils/helpers';

// Layout
@import 'layout/general';
@import 'layout/sections';
@import 'layout/alerts';
@import 'layout/footer';

// Components
@import 'components/type';
@import 'components/images';
@import 'components/buttons';
@import 'components/forms';
@import 'components/lists';
@import 'components/accordions';
@import 'components/tabs';

// Partials
@import 'partials/icon-block';

// Plugins
@import 'plugins/slick';