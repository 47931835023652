// Background scaling
.bg-cover{
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-cover-center{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.bg-contain{
    background-size: contain;
    background-repeat: no-repeat;
}

.bg-contain-center{
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

// Background colours
.bg-primary{
    background-color: $primary;
}

.bg-secondary{
    background-color: $secondary;
}

.bg-tertiary{
    background-color: $tertiary;
}

.bg-grey{
    background-color: $lightgrey;
}

.bg-gradient-primary-secondary{
    background: linear-gradient(90deg, $primary, $secondary);
}

.bg-transparent{
    background-color: transparent;
}

.valign-middle{
    vertical-align: middle;
}

.valign-text-top{
    vertical-align: text-top;
}

// Overflows
.overflow-hidden{
    overflow: hidden;
}

// Positions
.position-static {
    position: static;
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.position-fixed {
    position: fixed;
}

// Borders
.b-0{
    border: 0px;
}

// Width & Height
.vh-100{
    height: 100vh;
}