.accordion{
    .card{
        border-radius: 0;
        border: 0;
        margin-bottom: 15px;
        background: transparent;

        &-header{
            border-radius: 0;
            border: 0;
            color: $primary;
            padding: 0;
            background: transparent;

            h2{
                button{
                    width: 100%;
                    padding: 0;
                    font-size: 18px;
                    font-weight: 600;
                    padding: 17px 20px;
                    text-align: left;
                    border-radius: 0;
                    text-decoration: none;
                    border-top: 1px solid $darkgrey;
                    background: transparent;

                    ion-icon{
                        font-size: 25px;
                        margin-right: 5px;
                        color: $secondary;
                        vertical-align: text-top;
                    }

                    &[aria-expanded='true']{
                        .closed{
                            display: none;
                        }

                        .open{
                            display: inline-block;
                        }
                    }

                    &[aria-expanded='false']{
                        .closed{
                            display: inline-block;
                        }

                        .open{
                            display: none;
                        }
                    }
                }
            }
        }

        &:last-child{
            &-header{
                h2{
                    button{
                        border-bottom: 1px solid $midgrey;
                    }
                }
            }
        }
    }
}