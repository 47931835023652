.alert{
    position: fixed;
    border-radius: 0px;
    border: 0px;
    padding-left: 80px;
    color: #fff;
    bottom: 20px;
    right: 30px;
    z-index: 999;
    opacity: 1;
    @include box-shadow(0px, 0px, 10px, rgba(0,0,0,0.5));
    @include transition(all 0.2s ease);

    p, ul li{
        font-family: $primary-font;
    }

    .icon{
        position: absolute;
        width: 60px;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0,0,0,0.3);

        ion-icon{
            font-size: 50px;
        }
    }

    &-success{
        background: $success;
    }

    &-danger{
        background: $danger;
    }

    &-transition{
        opacity: 0;
        @include transform(translateX(100%));
    }

    @include media-breakpoint-down(sm){
        left: 10px;
        right: 10px;
        bottom: 5px;
        padding: 30px;

        .icon{
            display: none;
        }

        p, ul li{
            font-size: 14px;
        }
    }
}