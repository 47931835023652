.nav-tabs{
    position: relative;
    border: 0;
    border-radius: 100px;
    padding: 4px 4px 5px 4px;
    z-index: 999;
    margin-bottom: 60px;
    background: $lightgrey;

    .nav-item{
        >a{
            font-family: $primary-font;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            border-radius: 100px;
            border: 0;
            color: $secondary;
            padding: 20px 0;
            background: transparent;

            &.active{
                color: $primary;
                background: #fff;
            }
        }

        @include media-breakpoint-down(sm){
            width: 100%;

            >a{
                width: 100%;
            }
        }
    }

    @include media-breakpoint-down(sm){
        border-radius: 30px;
        margin-bottom: 20px;
    }
}

.tab-content{
    .image{
        position: absolute;
        width: 85%;
        height: 100%;
        right: -30%;
        bottom: 0;
        z-index: -1;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;

        &:after, &:before{
            position: absolute;
            content: '';
            opacity: 1;
            border-radius: inherit;
        }

        &:after{
            top: 0;
            left: 0;
            right: 60%;
            bottom: 0;
            background: rgb(255,255,255);
            background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%);
        }

        &:before{
            top: 0;
            left: 0;
            right: 0;
            bottom: 70%;
            background: rgb(255,255,255);
            background: linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%);
        }
    }

    p{
        strong{
            font-family: $primary-font !important;
            color: $primary !important;
        }
    }

    .stat{
        padding-left: 80px;

        h2{
            font-size: 2.6rem;
            margin-bottom: 0px;
        }

        p{
            margin-top: 0;
        }

        ion-icon{
            position: absolute;
            left: 0;
            color: $primary;
            font-size: 70px;
        }
    }
}

#tabs-row{
    position: relative;
}