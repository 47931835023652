.form-container{
	border-radius: 0px;
	background: #fff;
	@include box-shadow(0px, 0px, 20px, rgba(0,0,0,0.8));
}

.form-group{
	position: relative;
	margin-bottom: 25px;
	width: 100%;
	display: block;

	label{
		font-size: 14px;
		color: $primary;
		margin-bottom: 0px;
		padding-bottom: 0px;
	}

	&.file-finder{
        position: relative;

        button{
            position: absolute;
            border-radius: 0px;
            right: 2px;
            bottom: 2px;
        }
	}
	
	&.form-error{
		.input-underline, .select{
			padding-right: 30px;
			border-color: $danger;
		}

		&:after{
			position: absolute;
			font-family: "Font Awesome 5 Free" !important;
			font-weight: 900;
			content: '\f071';
			right: 5px;
			top: 40px;
			color: $danger;
		}
	}
}

input[type=text], input[type=email], input[type=password],input[type=number] , textarea{
    width: 100%;
    border-radius: 5px;
    padding: 15px 12px;
    border: 0px;
	outline: none;
	resize: none;
	font-weight: 400;
	color: $primary;
	background: #fff;
	@include transition(all 0.3s ease);

    &.input-bordered{
        border: 1px solid #ddd;
	}
	
	&.input-rounded{
		border-radius: 30px;
	}

	&.input-underline{
		padding: 15px 0px;
		border-radius: 0px;
		border-top: 0px;
		border-left: 0px;
		border-right: 0px;
		border-bottom: 1px solid $primary;
		background: transparent;
	}
	
	&:focus{
		border-color: $secondary;
	}
}

// Dropdowns
.select{
	position: relative;
	font-family: $body-font;
	width: 100%;
	display: block;
	border-bottom: 1px solid $primary;
	padding: 0px;

	ion-icon{
		position: absolute;
		top: 50%;
		right: 15px;
		font-size: 20px;
		color: $primary;
		@include transform(translateY(-50%));
	}

	select{
		position: relative;
		width: 100%;
		display: block;
		border: 0;
		font-family: inherit;
		font-weight: 400;
		color: $primary;
		margin-top: 0px;
		text-indent: 0.01px;
		padding: 15px 0px;
		text-overflow: '';
		outline: none ;
		-webkit-appearance: none;
		-moz-appearance: none;
		background-color: transparent;
	
		  option{
			color: #000;
			outline: none;
			background: #fff;
		}
	}

	&-white{
		border-color: #fff;
		background: #fff;
	}
}

// Checkboxes and Radio buttons //
.checkbox {
	display: block;
	position: relative;
	margin-top: 10px;
	margin-bottom: 10px;
	padding-left: 30px;
	font-size: 14px;
	line-height: 21px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;

		/* Show the checkmark when checked */
		&:checked ~ .checkmark ion-icon {
			opacity: 1 !important;
			display: block;
			transition: all 0.3s ease-in-out;
		}
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		padding: 0px;
		text-align: center;
		border-radius: 4px;
		border: 1px solid $midgrey;
		background: #fff;

		ion-icon{
			position: absolute;
			display: block;
			font-size: 18px;
			top: -1px;
			left: 0px;
			right: 0;
			opacity: 0;
			margin-top: 2px;
			color: $primary;
			transition: all 0.3s ease-in-out;
		}
	}

	/* On mouse-over, add a grey background color */
	&:hover input ~ .checkmark ion-icon {
		opacity: 0.4;
		display: block;
		transition: all 0.3s ease-in-out;
	}
}