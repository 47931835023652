
// Body
$body-bg: #fff;

// Fonts
$primary-font: 'Poppins', 'sans-serif';
$body-font: 'Lora', 'sans-serif';

// Colors
$primary: #0e2b4d;
$secondary: #d8b170;
$tertiary: #23345f;

$darkgrey: #929292;
$midgrey: #eaeae9;
$lightgrey: #ebedec;

$success: #5cb446;
$danger: #a72525;
$danger-light: #b62d2d;