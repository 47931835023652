h1,h2,h3,h4,h5,h6,.button{
    font-family: $primary-font;
}

h4{
    font-size: 1.4rem;
}

a,p,input,textarea{
    font-family: $body-font;
}

a.link{
    &:hover{
        color: $secondary;
    }
}

i.fas{
    font-family: "Font Awesome 5 Free" !important;
}

p{
    font-size: 17px;
}

@include media-breakpoint-down(sm){
    h2{
        font-size: 22px;
    }

    h3{
        font-size: 20px;
    }

    h4{
        font-size: 18px;
    }

    h5{
        font-size: 16px;
    }

    h6{
        font-size: 14px;
    }

    p{
        font-size: 15px;
    }
}

// Colours

.format-primary{
    color: $primary;
}

.format-secondary{
    color: $secondary;
}

.format-tertiary{
    color: $tertiary;
}

.format-white{
    color: #fff;
}

.format-grey{
    color: $midgrey;
}

.format-darkgrey{
    color: $darkgrey;
}


// Formatting

.uppercase{
    text-transform: uppercase;
}

.bold{
    font-weight: bold;
}

.semi-bold{
    font-weight: 600;
}

.italic{
    font-style: italic;
}