.btn{
    // Size & Formatting

    &-rounded{
        border-radius: 50px;

        &.btn-lg{
            padding: 12px 35px;
            border-radius: 20px;
        }
    }

    &-lg{
        font-size: 17px;
    }

    &-xl{
        font-size: 20px;
        padding: 15px 60px;

        @include media-breakpoint-down(sm){
            padding: 10px 0px;
        }
    }


    // Colours
    &-tertiary{
        color: #fff;
        background: $tertiary;
    }

    &-white{
        color: $primary;
        background: #fff;
    }

    &-grey{
        color: $primary;
        border-color: $midgrey;
        background: $midgrey;
    }

    &-hover-secondary{
        &:hover{
            color: #fff;
            border-color: $secondary;
            background: $secondary;
        }
    }

    &-hover-tertiary{
        &:hover{
            color: #fff;
            border-color: $tertiary;
            background: $tertiary;
        }
    }

    &-hover-grey{
        &:hover{
            color: $primary;
            border-color: $midgrey;
            background: $midgrey;
        }
    }

    &-hover-white{
        &:hover{
            color: $primary;
            border-color: #fff;
            background: #fff;
        }
    }

    @include media-breakpoint-down(sm){
        width: 100%;
    }
}