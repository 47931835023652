// slick styles //
.slick-track{
	div{
		outline: none;
	}
}

.slick-arrow{
	position: absolute;
	min-height: 0;
	top: 45%;
	font-size: 40px;
	text-align: center;
	padding: 0px;
	z-index: 998;
	color: #fff;
	transition: all 0.2s ease-in-out;

	&:hover{
		color: $primary;
	}
}

.slick-prev{
	left: 30px;
}

.slick-next{
	right: 30px;
}

.slick-dots{
	position: absolute;
	left: 0;
	right: 0;
	text-align: inherit;
	display: inline-block;
	margin-top: 10px;
	margin-bottom: 0px;
	padding-bottom: 0px;
    padding-left: 0px;

	li{
		display: inline-block;

		button{
			padding: 0px ;
			outline: none ;
			margin: 5px;
			border-radius: 100%;
			border: 1px solid rgba(255,255,255,0.5);
			width: 12px;
			height: 12px;
			content: none;
            color: transparent;
		}
	}

	li:not(.slick-active) button{
		background: rgba(255,255,255,0.5);
	}

	.slick-active button{
        border-color: $tertiary;
		background:$tertiary !important;
	}
}