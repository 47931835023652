@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    transition: $args;
}

@mixin transform($args...) {
    -webkit-transform: $args;
    -moz-transform: $args;
    transform: $args;
}

@mixin linear-gradient($direction, $color-stops...) {
    // Direction has been omitted and happens to be a color-stop
    @if is-direction($direction) == false {
      $color-stops: $direction, $color-stops;
      $direction: 180deg;
    }
  
    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow:inset $top $left $blur $color;
        -moz-box-shadow:inset $top $left $blur $color;
        box-shadow:inset $top $left $blur $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}