.section{
    position: relative;
    padding: 60px 0px;

    &#hero{
        min-height: 650px;

        @include media-breakpoint-down(md){
            min-height: auto;
        }
    }

    &-overlay{
        &:after{
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.9;
            z-index: 1;
        }

        >div{
            z-index: 9;
            position: relative;
        }
    }

    &-overlay-logo{
        &:before{
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            opacity: 0.05;
            background-position: right;
            background-size: contain;
            background-image: url('/images/patterns/hero-overlay-large.png');
            background-repeat: no-repeat;

            @include media-breakpoint-down(sm){
                background-size: cover;
            }
        }
    }

    &-overlay-primary{
        &:after{
            background-color: $primary;
        }
    }

    &-overlay-white{
        &:after{
            background-color: #fff;
        }
    }

    >.overlay{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.1;
        background-size: cover;
        background-position: center;
    }

    @include media-breakpoint-down(sm){
        padding: 30px 0px;
    }
}